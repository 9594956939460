/*
 * @Author       : JiangChao
 * @Date         : 2022-02-19 11:51:03
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-22 21:47:57
 * @Description  : 弹窗通用
 */
const handleErr = (err) => {
    console.log('err: ', err);
    if (!err) return;
    if(err.code=='INVALID_CONFIG'){
        return
    }
    let msg = "";
    if (err.msg) {
        msg = err.msg;
    } else if (err.data) {
        switch (err.data.msg) {
            case "verification.code.error":
                msg = "驗證碼錯誤";
                break;
            default:
                msg = err.data.msg;
        }
    }else{
        msg = err.code;
    }
    err.app.openAlertDialog(msg);
};
export { handleErr };
