<!--
 * @Author       : JiangChao
 * @Date         : 2022-02-19 11:51:03
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-13 20:09:53
 * @Description  : 
-->
<template>
    <div id="app">
        <router-view />
        <AlertDialog
            v-if="isAlertActive"
            :alertMsg="alertMsg"
            buttonLabel="確認"
            @close="$_closeAlertDialog()"
        />
    </div>
</template>
<script>
import AlertDialog from "@/components/AlertDialog";
import "@/assets/sass/app.scss";
import "@/assets/sass/reset.scss";
import "@/assets/sass/special.scss";
import { handleErr } from "@/helpers";
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
    components: {
        AlertDialog,
    },
    data() {
        return {
            isAlertActive: false,
            alertMsg: "",
            timer2: null,
        };
    },
    watch: {
        $route(to, from) {
            // console.log("微信静默授权", this.$CONFIG);
            console.log("to", to);
            console.log("from", from);
            // this.loopResultBack()
        },
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        //测试用
        // this.setOrder2();
        if (!this.timer2) {
            this.loopResultBack();
        }
    },
    methods: {
        openAlertDialog(msg) {
            console.log("msg: ", msg);
            // if (msg == "INVALID_CONFIG") {
            this.isAlertActive = true;
            this.alertMsg = msg;
        },
        $_closeAlertDialog() {
            this.isAlertActive = false;
            this.alertMsg = "";
        },
        loopResultBack() {
            this.timer2 = window.setInterval(() => {
                this.checkOrder2();
            }, 2000);
        },
        async checkOrder2() {
            try {
                // Toast.loading({
                //     message: "正在归还，請稍後...",
                //     forbidClick: true,
                // });
                const res = await this.$axios.get(
                    "/customer/order/checkOrder2"
                );
                console.log("res: ", res);
                if (res.data.success) {
                    switch (res.data.result.status) {
                        case "1":
                            // window.clearInterval(this.timer2);
                            Dialog({
                                message: `行動電源已歸還成功，本次消費${
                                    res.data.result.money || 0
                                }元，感謝您的使用`,
                                confirmButtonText: "確認",
                            });
                           
                            break;
                    }
                }
            } catch (error) {
                Toast.clear();
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
         async setOrder2() {
            try {
                const res = await this.$axios.get(
                    "/customer/order/setOrder2"
                );
                console.log("res: ", res);
                if (res.data.success) {
                    // switch (res.data.result.status) {
                    // }
                }
            } catch (error) {
                Toast.clear();
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
};
</script>
