<template>
  <div
    class="light-box flex-container flex-center center flex-center-h"
    @click.self="$_closeAlertDialog()"
  >
    <div class="box flex-container flex-column flex-center center">
      <span class="text-1500 text-b title">提醒</span>
      <span class="text-1250">{{alertMsg}}</span>
      <button
        type="button"
        class="capsule-button text-875 capsule-small check"
        @click="$_closeAlertDialog()"
      >
        {{buttonLabel}}
      </button>
    </div>
  </div>
</template>
<script>
export default {
    name: "AlertDialog",
    props: {
        alertMsg: {
            type: String,
            default: "",
        },
        buttonLabel: {
            type: String,
            default: "",
        },
    },
    methods: {
        $_closeAlertDialog() {
            this.$emit("close");
        },
    },
};
</script>