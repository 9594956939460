/*
 * @Author       : JiangChao
 * @Date         : 2022-02-19 11:51:03
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-12 14:31:55
 * @Description  :
 */
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import moment from "moment";
import liff from "@line/liff";
import VueCollapse from "vue2-collapse";
import VueQrcodeReader from "vue-qrcode-reader";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Vant from "vant";
import "vant/lib/index.css";
import Vuex from "vuex";
// import VueAppleSignin from 'vue-apple-signin';

import $ from "jquery";
window.jQuery = $;
window.$ = $;

Vue.use(Vant);
Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$liff = liff;

Vue.component("VueperSlides", VueperSlides);
Vue.component("VueperSlide", VueperSlide);
Vue.use(VueCollapse);
Vue.use(VueQrcodeReader);
Vue.use(PerfectScrollbar);
import { Overlay } from "vant";

Vue.use(Overlay);

//cookies配置
// import cookies from 'vue-cookies'
// Vue.prototype.$cookies=cookies;
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyCRs2FNXC8IeiZOStZmA0adgUzw_NvFIBQ",
        libraries: "places", // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },
    installComponents: true,
});

Vue.use(Vuex);
import { mixins } from "./mixin/mixin_websocket.js";
Vue.mixin(mixins);
import store from "./store/modules/websocket";


new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
