/*
 * @Author       : JiangChao
 * @Date         : 2023-04-28 10:55:16
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-05-08 11:41:54
 * @Description  :
 */


export const mixins = {
    data() {
        return {
            timer2: "",
        };
    },
    destroyed() {
        window.clearInterval(this.timer2);
    },
    computed: {
        global_uuid() {
            const subjectIcfRecordId = this.$store.getters.subjectIcfRecordId;
            const subjectId = this.$store.getters.subjectId;
            return `${subjectIcfRecordId}-1-${subjectId}`;
        },
    },
    methods: {
        // websocket建立链接
        // global_websocket_connect() {
        //     const baseUrl = process.env.VUE_APP_API_BASE_URL;
        //     console.log("baseUrl: ", baseUrl);
        //     const wsUrl = `${baseUrl}/rent-socket2`;
        //     console.log("wsUrl: ", wsUrl);
        //     // console.log('this.$store: ', this.$store);
        //     this.$store.dispatch("WEBSOCKET_INIT", wsUrl); // 建立链接
        // },
        // // 发送消息
        // global_websocket_send_message(code) {
        //     const params = {
        //         subjectIcfRecordId: this.$store.getters.subjectIcfRecordId,
        //         code: code,
        //     };
        //     this.$store.dispatch("PUSH_CODE", params);
        // },
        // // 接收订阅消息
        // global_websocket_receive_message() {
        //     const stompClient = this.$store.getters.stompClient;
        //     const interval = setInterval(() => {
        //         // 增改轮询解决刷新问题
        //         if (stompClient != null && stompClient.connected) {
        //             clearInterval(interval);
        //             stompClient.subscribe(
        //                 `/topic/${this.global_uuid}/messages`,
        //                 (res) => {
        //                     const result = JSON.parse(res.body);
        //                     console.log("接收消息", result);
        //                     if (
        //                         result.data === "2203" ||
        //                         result.data === "2106"
        //                     ) {
        //                         // 电子签名按钮可以显示
        //                         this.$store.commit("SET_SHOW_SIGN_BTN", true);
        //                     }
        //                     if (result.data === "2101") {
        //                         // 知情同意悬浮按钮显示
        //                         this.$store.commit("SET_SHOW_READ_BTN", true);
        //                         this.toRead();
        //                     }
        //                 }
        //             );
        //         } else {
        //             console.log("等待连接中");
        //         }
        //     }, 1000);
        // },
        // // 取消订阅消息
        // global_websocket_unsubscribe() {
        //     const stompClient = this.$store.getters.stompClient;
        //     if (stompClient != null && stompClient.connected) {
        //         this.$store.dispatch(
        //             "WEBSOCKET_UNSUBSRCIBE",
        //             `/topic/${this.global_uuid}/messages`
        //         );
        //     }
        // },
        // // 获取url参数方法
        // global_getQueryString(name) {
        //     var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        //     var r = window.location.search.substr(1).match(reg);
        //     if (r != null) return unescape(r[2]);
        //     return null;
        // },
    },
};
