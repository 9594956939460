import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        // {
        //     path: "/",
        //     name: "auth",
        //     component: () => import("@/views/Auth.vue"),
        // },
        {
            path: "/login",
            name: "login",
            component: () => import("@/views/Login.vue"),
            meta: {
                saveBtn: true,
            },
        },
        {
            path: "/emailCheck",
            name: "emailCheck",
            component: () => import("@/views/emailCheck.vue"),
            // meta: {
            //     requiresAuth: true,
            // },
        },
        {
            path: "/BindMobile",
            name: "BindMobile",
            component: () => import("@/views/BindMobile.vue"),
            // meta: {
            //     requiresAuth: true,
            // },
        },
        {
            path: "/BindMobile2",
            name: "BindMobile2",
            component: () => import("@/views/BindMobile2.vue"),
            // meta: {
            //     requiresAuth: true,
            // },
        },
        {
            path: "/members",
            name: "members",
            component: () => import("@/views/Members.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/contact",
            name: "contact",
            component: () => import("@/views/Contact.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/Business",
            name: "Business",
            component: () => import("@/views/Business.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/questions",
            name: "questions",
            component: () => import("@/views/Questions.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/coupon",
            name: "coupon",
            component: () => import("@/views/Coupon.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/terms",
            name: "terms",
            component: () => import("@/views/Terms.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/map",
            name: "map",
            component: () => import("@/views/Map.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/records",
            name: "records",
            component: () => import("@/views/Records.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/search",
            name: "search",
            component: () => import("@/views/Search.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/binding",
            name: "binding",
            component: () => import("@/views/Binding.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/store-box",
            name: "store-box",
            component: () => import("@/views/StoreBox.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/scanner",
            name: "scanner",
            component: () => import("@/views/Scanner.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/store/:storeID",
            name: "store",
            component: () => import("@/views/Store.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/confirm-device",
            name: "confirm-device",
            component: () => import("@/views/ConfirmDevice.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/confirm-device/:deviceID",
            name: "rent-device",
            component: () => import("@/views/RentDevice.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/linepay/callback",
            name: "linepayCallback",
            component: () => import("@/views/LinePayCallback.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/linepay/callback2",
            name: "linepayCallback2",
            component: () => import("@/views/LinePayCallback2.vue"),
            meta: {
                requiresAuth: true,
            },
        },

        {
            path: "/linepay/callback/cancel",
            name: "linepayCallbackCancel",
            component: () => import("@/views/LinePayCallbackCancel.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/PayResultWeb",
            name: "PayResultWeb",
            component: () => import("@/views/PayResultWeb.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/",
            name: "NewLogin",
            component: () => import("@/views/NewLogin.vue"),
        },
        {
            path: "/Agreement",
            name: "Agreement",
            component: () => import("@/views/Agreement.vue"),
        },
        {
            path: "/PersonalInfo",
            name: "PersonalInfo",
            component: () => import("@/views/PersonalInfo.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/OrderDetail",
            name: "OrderDetail",
            component: () => import("@/views/OrderDetail.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/DepositMethod",
            name: "DepositMethod",
            component: () => import("@/views/DepositMethod.vue"),
        },
        {
            path: "/PayByBankCard",
            name: "PayByBankCard",
            component: () => import("@/views/PayByBankCard.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/PayByMultiChannel",
            name: "PayByMultiChannel",
            component: () => import("@/views/PayByMultiChannel.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/PayBySubscription",
            name: "PayBySubscription",
            component: () => import("@/views/PayBySubscription.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/BindCreditCard",
            name: "BindCreditCard",
            component: () => import("@/views/BindCreditCard.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/GooglePay",
            name: "GooglePay",
            component: () => import("@/views/GooglePay.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/RefundDeposit",
            name: "RefundDeposit",
            component: () => import("@/views/RefundDeposit.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/CancelBind",
            name: "CancelBind",
            component: () => import("@/views/CancelBind.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/Subscription",
            name: "Subscription",
            component: () => import("@/views/Subscription.vue"),
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: "/CancelSubscription",
            name: "CancelSubscription",
            component: () => import("@/views/CancelSubscription.vue"),
            meta: {
                requiresAuth: true,
            },
        },

        


        
    ],
});

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    // const token = localStorage.getItem("token");
    const token = window.$cookies.get("token");
    // console.log('token: ', token);
    // console.log("token router", token, localStorage, "router localStorage");
    // console.log(from, "from", to, "to");
    if (requiresAuth && !token) {
        next({ path: "/" });
        return;
    }
    next();
    return;
});

export default router;
